import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import footerlogo from '../../assets/images/logo/MindoxTechno.png';
import { FaFacebookF,FaTwitter,FaInstagram ,FaLinkedinIn} from "react-icons/fa";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function Footer() {

   

  return (
   <footer>
        <div className="footer-top">
            <div className="footer-logo">  <LazyLoadImage  src={footerlogo} alt="MindoxTechno Logo" width="200px" height="160px"/></div>
            <div className="footer-container">
                <div className="footer-link f1">
                    <h4>Explore us</h4>
                    <h5><Link to="/">Home</Link></h5>
                    <h5><Link to="/company">About Mindox Techno</Link></h5>
                    <h5><Link to="/development.html"> Development</Link></h5>
                    <h5><Link to="/contact.html"> Contact Us</Link></h5>
                </div>
                <div className="footer-link f2">
                    <h4>Our Products</h4>
                    <h5><Link to="/product/efem">Hybrid Equipment <br/>Front End Module (EFEM)</Link></h5>
                  
                    {/* <h5><Link to="/metrology.html">Inline (Integrated) Inspection Tool</Link></h5>                   */}
                    <h5><Link to="/spin-spray-coater.html">Spin/Spray Coating</Link></h5>
                    
                         
                </div>
                <div className="footer-link f3">
                    <h4>Our Services</h4>
                    <h5><Link to="/services/extended-services.html">Extended Services</Link></h5>
                    {/* <h5><Link to="/services/advanced-analytics.html">Advanced Analytics</Link></h5> */}
                           
                </div>
                <div className="footer-link f4">
                    <h4> Get In Touch</h4>
                    <h5>7 Gambas Crescent, #08-24, Singapore, 757087.</h5>
                    <h5><a href="mailto:info@mindoxtechno.com">info@mindoxtechno.com</a></h5>
                    <h5><a href="tel:+6569838172">+65 6983 8172</a></h5>
                    <div className="footer-icon">
                        <div className="footer-icon-box"> <a aria-label="Facebook" href="//www.facebook.com/mindoxtechno/"><FaFacebookF  /></a>  </div>
                        <div className="footer-icon-box"> <a aria-label="Twitter" href="//www.twitter.com/mindoxtechno/"><FaTwitter  /></a>  </div>
                        <div className="footer-icon-box"> <a  aria-label="Instagram" href="//www.Instagram.com/mindoxtechno/"><FaInstagram  /></a>  </div>
                        <div className="footer-icon-box"><a  aria-label="LinkedIn" href="//www.linkedin.com/company/mindox-techno/"><FaLinkedinIn  /></a> </div>
                     </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
             <p> Copyright © {new Date().getFullYear()}{" "}<span> Mindox Techno</span>, All rights reserved.</p>
        </div>
   </footer>
  );
}

export default Footer;
