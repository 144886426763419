import React, { useState,useRef  } from "react";
import { Link,NavLink ,useLocation,useNavigate  } from "react-router-dom";
import "./navbar.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {FaBars,FaAngleDown,FaAngleUp} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import logo from '../../assets/images/logo/MindoxTechno.png';


function Navbar() {
  const location = useLocation();

  const[Mobile,setMobile] = useState(false)
    const[Phone,setPhone] = useState(false)

    const endEffectorRef = useRef(null);
    const LPRef = useRef(null);
    const [activeLink, setActiveLink] = useState("");
   
    const handleEndEffectorClick = () => {
      setActiveLink("/universal-efem/efem-accessories#EEdiv");
      const element = document.getElementById('EEdiv');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    };

    
    const handleLPClick = () => {
      setActiveLink("/universal-efem/efem-accessories#LPdiv");
     
      const element = document.getElementById('LPdiv');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

   
  return (
   <nav>
      <div  className="navbar">
        <div className="logo"><Link to="/" onClick={() => {window.location.href="/"}} > <LazyLoadImage  src={logo} alt="MindoxTechno Logo"/></Link></div>
          <ul className={Mobile? "nav-links-mobile" :"nav-links" } onClick={()=>setMobile(false)}>
            <li className={`dropdown ${location.pathname.includes("/product") || window.location.pathname.includes("/coater")  ? "active" : ""}`}>Products <span className="phone-icon"><FaAngleDown/></span>
                  <div  className="dropdown-content">
                    <ul>
                    <li className={`dropdown-sub ${location.pathname === "/product" ? "active" : ""}`}>
              <NavLink to="/product">EFEM & ACCESSORIES</NavLink>
            </li>
            <li className={`dropdown-sub ${location.pathname === "/product/accessories#LPdiv" ? "active" : ""}`} onClick={handleLPClick} ref={LPRef}>
              <NavLink to="/product/accessories#LPdiv">LOAD PORT </NavLink>
            </li>
            <li className={`dropdown-sub ${location.pathname === "/product/accessories#EEdiv" ? "active" : ""}`}  onClick={handleEndEffectorClick} ref={endEffectorRef} >
              <NavLink to="/product/accessories#EEdiv">END EFFECTOR  </NavLink>
            </li>
                      {/* <li className={`dropdown-sub ${location.pathname.includes("/metrology.html") ? "active" : ""}`}><NavLink to="/metrology.html">INLINE (INTEGRATED) INSPECTION TOOL</NavLink></li>                      */}
                      <li className={`dropdown-sub ${location.pathname.includes("/coater") ? "active" : ""}`}><NavLink to="/spin-spray-coater.html"> SPIN/SPRAY COATING</NavLink></li>
                     
                  </ul>
                 
                   
                    
                    
                    
                  </div>
            </li>
            <li  className={`dropdown ${location.pathname.includes("/services") ? "active" : ""}`}><NavLink to="/services"  onClick={() => {window.location.href="/services"}} activeclassname="selected"  >Services</NavLink>
                  <div  className="dropdown-content">
                    <ul>
                      <li className="dropdown-sub"><NavLink to="/services/extended-services.html">Extended Services </NavLink> </li>
                      {/* <li><NavLink to="/services/advanced-analytics.html">Advanced Analytics</NavLink></li> */}
                      
                    </ul>
                  </div>
            </li>
            <li className={`dropdown ${location.pathname.includes("/development") ? "active" : ""}`}><NavLink to="/development" onClick={() => {window.location.href="/development"}} activeclassname="selected" >Development</NavLink>
                <div  className="dropdown-content">
                    <ul>
                      <li className="dropdown-sub"><NavLink to="/development/new-product-development.html">New Product Development </NavLink> </li>
                      <li><NavLink to="/development/knowledge-Hub.html">Knowledge Hub</NavLink></li>
                      
                    </ul>
                  </div>
            </li>
            <li className={`dropdown ${location.pathname.includes("/company") ? "active" : ""}`}> <NavLink to="/company" onClick={() => {window.location.href="/company"}} activeclassname="selected">Company</NavLink>
                 <div  className="dropdown-content">
                    <ul>
                      <li className="dropdown-sub"><NavLink to="/company/about-us.html">About us</NavLink> </li>
                      <li><NavLink to="/company/careers.html">Mindox Careers</NavLink></li>
                      
                    </ul>
                  </div>
            </li>
            <li className={`dropdown ${location.pathname.includes("/contact") ? "active" : ""}`}><NavLink to="/contact.html" onClick={() => {window.location.href="/contact.html"}} activeclassname="selected">Contact</NavLink></li>
          </ul>
          <button className="mobile-menu-icon" onClick={()=>setMobile(!Mobile)}> {Mobile? <ImCross/> : <FaBars/>}</button>
         
      </div>
     
   </nav>
  );
}

export default Navbar;
